import * as React from "react";

interface Props {

}

const NotFound: React.FunctionComponent<Props> = () => {
    return (
        <div>
            Page not found.
            yes, the page is totally not found.<br />
            Page not found.
            yes, the page is totally not found.<br />
            Page not found.
            yes, the page is totally not found.<br />
            Page not found.
            yes, the page is totally not found.<br />
            Page not found.
            yes, the page is totally not found.<br />
            Page not found.
            yes, the page is totally not found.<br />
        </div>
    )
}

export default NotFound;